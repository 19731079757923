@import "../../styles/variable.scss";

.service-section {
  .hide{
    overflow: hidden;
    &:hover{
      .price-block{
        top: 0;
        margin: 0 1px;
        width: auto;
      }
    }
  }
  .price-block{
    position: absolute;
    width: auto;
    right: 14px;
    background: $line;
    text-align: center;
    padding: 7px 15px;
    color: #fff;
    transition: all ease 0.5s;
    font-size: 20px;
    border-radius: 0 10px 0 0;
    z-index: 2;
    top: -44px;
  }
  .service-img {
    img {
      width: 100%;
      height: 225px;
      border-radius: 10px;
      object-fit: cover;
    }
    .img-overlay {
      height: 225px;
      border-radius: 10px;
      width: 255px;
      margin: 0 15px;
      background-color: rgb(23 22 22 / 36%);
      top: unset;
    }
    .text-overlay {
      color: white;
      position: relative;
      bottom: 35px;
      padding: 0 15px;
      float: right;
    }
  }
  .card-block {
    .box-shadow {
      margin-right: 10px;
      min-width: 250px;
      .ptb-10 {
        padding: 7px 0 12px 0;
      }
    }
  }
  .alignment1{
    .card-block{
    &:nth-child(1){
      align-items: flex-end !important;
    }
    &:nth-child(2){
      align-items: flex-start !important;
    }
  }
  }
}


@media(max-width:992px){
  .service-section{
    .service-img{
      .img-overlay {
        width: 211px;
      }
    }
    .card-block{
      .box-shadow {
        min-width: 204px;
      }
    }
  }
}

@media(max-width:768px){
  .service-section{
    .card-block{
      .box-shadow {
        min-width: 175px;
        .main-title {
          font-size: 20px;
        }
      }
    }
  }
}

@media(max-width:480px){
  .service-section{
    .mt-60{
      .main-btn{
        margin-bottom: 60px;
      }
    }
    .card-block{
      .box-shadow {
        min-width: 205px;
      }
    }
  }
}

@media(max-width:375px){
  .service-section{
    .service-img{
      .img-overlay {
        width: 158px;
      }
    }
    .full-length-box{
      flex-wrap: wrap;
      width: 100%;
    }
    .card-block{
      .mr-20{
        margin-right: 0;
        .box-shadow {
          min-width: 100%;
        }
      }
    }
  }
}

@media(max-width:320px){
  .service-section{
    .service-img{
      .img-overlay {
        width: 131px;
        height: 180px;
      }
      img{
        height: 180px;
      }
      .text-overlay{
        bottom: 48px;
        text-align: center;
      }
    }
    .card-block{
      .box-shadow {
        .main-title {
          font-size: 18px;
        }
      }
    }
  }
}