@import "../../styles/variable.scss";

.navbar {
    z-index: 2;
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;

    .nav-item {
        margin: 0 20px;
        font-size: 16px;

        .nav-link {
            color: white !important;
        }
    }

    .navbar-nav {
        font-size: 15px;
        // justify-content: flex-end;
        // align-items: center; 
        // min-width: 1000px;
    }
}

.scrolled {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    z-index: 100;

    .navbar {
        position: fixed;
        width: 100%;
        border-radius: 0;
        top: 0;
        left: 0;
        background: #fff;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 38%);
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        -webkit-animation: fadeInDown 1s both 0.2s;
        animation: fadeInDown 1s both 0.2s;

        .nav-item {
            .nav-link {
                color: $titleColor !important;

                &:hover {
                    color: $green !important;
                }
            }
        }
    }
}

@media(max-width:768px) {
    .navbar {
        position: unset;
    }

    .navbar-collapse {
        .navbar-nav {
            margin-left: 0 !important;
            box-shadow: rgba(33, 35, 38, 0.1) 8px 10px 10px -10px;
            border-radius: 10px;
            margin-bottom: 10px;
            padding: 15px 0;

            .nav-item {
                padding: 9px 20px;

                .nav-link {
                    color: $titleColor !important;
                    padding: 0 10px;
                }
            }
        }
    }
}