@import "../../styles/variable.scss";


.contact-section{
    .flex-block{
        i{
            color: $titleColor;
            font-size: 20px;
            padding-right: 12px;
        }
        .contact-text{
            padding-top: 7px;
            line-height: 0.95;
        }
    }
    .time{
        align-items: center;
        justify-content: space-between;
    }
}