@import "../../styles/variable.scss";

.video-section {
  .frame-box {
    margin-top: 55px;
    text-align: center;

    iframe {
      border-radius: 10px;
      width: 100%;
      height: 450px;
    }
  }

  .video-img {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  .box-shadow {
    border-radius: 0 0 10px 10px;

    .main-btn {
      color: $green;
      border: 1px solid $green;
      background-color: transparent;
      &:hover{
        background-color: $green;
        color: white;
      }
    }
    .main-title{
      font-size: 20px;
    }
  }

  .center-align {
    .middle {
      align-items: center;
    }

    .shadow-wrapper {
      box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
      margin-top: 20px;
      border-radius: 10px;
      .box-shadow{
        box-shadow: unset;
      }
    }

    .align-block{
      padding-left: 30px;
    }
  }
}

@media(max-width:480px){
  .video-section {
   .center-align{
     .align-block {
        padding-left: 0;
      }
      .shadow-wrapper{
        .box-shadow{
          .main-title{
            font-size: 25px;
          }
        }
      }
    }
  } 
}

@media(max-width:320px){
  .video-section {
   .center-align{
      .shadow-wrapper{
        .box-shadow{
          .main-title{
            font-size: 21px;
          }
        }
      }
    }
  } 
}

