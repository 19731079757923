@import "../../styles/variable.scss";

.feature-section {
  .text-color {
    color: $titleColor;
    font-size: 24px;
  }
  .border-top {
    border-top: 1px soild $contentColor !important;
    .circle{
      padding: 10px 19px;
      i{
        font-size: 32px;
        color: white;
      }
    }
  }
  .green{
    background-color: $green;
  }
  .orange{
    background-color: $orangeText;
  }
  .text-color-orange{
    color: $orangeText;
  }
  .text-color-green{
    color: $green;
  }
}
