@import "./_variable.scss";

.mtb-20 {
  margin: 20px 0;
}

.mb-30 {
  margin-bottom: 30px;
}

.mtb-40 {
  margin: 40px 0;
}

.mb {
  margin-bottom: 0;
}

.pt-50 {
  padding-top: 50px;
}

.ptb-10 {
  padding: 10px 0;
}

.mr-20 {
  margin-right: 20px;
}

.m-auto {
  margin: auto;
}

.mt-60 {
  margin-top: 60px;
}

.pb-15 {
  padding-bottom: 15px;
}


.col-sm-12 {
  width: 100% !important;
}

.col-sm-11 {
  width: 91.66% !important;
}

.col-sm-10 {
  width: 83.33% !important;
}

.col-sm-9 {
  width: 74.99% !important;
}

.col-sm-8 {
  width: 66.66% !important;
}

.col-sm-7 {
  width: 58.33% !important;
}

.col-sm-6 {
  width: 50% !important;
}

.col-sm-5 {
  width: 41.66% !important;
}

.col-sm-4 {
  width: 33.33% !important;
}

.col-sm-3 {
  width: 25% !important;
}

.col-sm-2 {
  width: 16.66% !important;
}

.col-sm-1 {
  width: 8.33% !important;
}

.col-sm-0 {
  width: 0;
}

.main-btn,
.transparent-btn {
  background-color: $green;
  color: white;
  font-size: 16px;
  outline: none !important;
}

.transparent-btn {
  background-color: transparent;
  border: 1px solid white !important;

  &:hover {
    background-color: $green;
    color: white;
    border: 1px solid $green !important;
  }
}

.main-btn {
  &:hover {
    background-color: transparent;
    border: 1px solid $green;
    color: $green;
  }
}

.btn-radius {
  border-radius: 20px;
  outline: none;
  padding: 10px 20px;
  border: 1px solid transparent;
}

.section-top {
  padding: 30px 0;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(23 22 22 / 56%);
}

.title-line {
  position: relative;

  &::before {
    content: "";
  }

  &::after {
    content: "";
    border-bottom: 3px solid $line;
    width: 120px;
    position: absolute;
    left: 2px;
    bottom: -14px;
    z-index: 1;
  }
}

.main-title {
  color: $titleColor;
}

.circle {
  width: 70px;
  height: 70px;
  background-color: $titleColor;
  border-radius: 50%;
}

.content {
  color: $contentColor;
  font-size: 16px;
}

.text-center {
  margin-bottom: 60px;

  .title-line {
    &::after {
      left: 0;
      margin: 0 auto;
      right: 0;
      width: 105px;
    }
  }
}

.box-shadow {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.title-center {
  margin: 0 350px;
}

.card-block {
  display: flex;
  align-items: center;
}

.flex-block {
  display: flex;
}

.logo-img {
  height: 75px;
  width: 100%;
}

.sticky-scroll {
  position: sticky;
  top: 0;
  position: -webkit-sticky;
}

.text-uppercase {
  text-transform: uppercase;
  font-size: 16px;
}

@media(max-width: 992px) {
  .title-center {
    margin: 0 200px;
  }

  .main-title {
    font-size: 30px;
  }
}

@media(max-width: 768px) {
  .title-center {
    margin: 0 90px;
  }
}

@media(max-width: 480px) {
  .main-title {
    font-size: 40px;
  }

  .title-center {
    margin: 0;
  }
}

@media(max-width: 375px) {
  .main-title {
    font-size: 32px;
  }

  .title-line::after {
    width: 95px;
  }
}

@media(max-width: 320px) {
  .main-title {
    font-size: 25px;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}
