.dental-page {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-image: url("https://493071-1777511-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/saludo-dental.jpg");
  width: 100%;
  min-height: 660px;
  .breadcrumb-content {
    position: relative;
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    min-height: 300px;
    .dental-text {
      position: absolute;
      top: 60%;
      bottom: inherit;
      padding: 0 33px;
      text-shadow: none;
      color: white;
      .title-line {
        margin-bottom: 50px;
        &::after {
          left: 2px;
          bottom: -25px;
        }
      }
      .text-content{
        .small-txt{
          font-size: 48px;
          font-weight: 500;
        }
        .large-txt{
          font-size: 72px;
        }
      }
      .txt{
        font-size: 38px;
      }
      .main-btn {
        margin-right: 20px;
      }
    }
  }
}

@media(max-width:768px){
  .dental-page{
    .breadcrumb-content{
      .dental-text{
        .txt{
          font-size: 32px;
        }
        .text-content{
          .small-txt {
            font-size: 40px;
          }
          .large-txt {
            font-size: 60px;
          }
        }
      }
    }
  }
}

@media(max-width:480px){
  .dental-page{
    .breadcrumb-content{
      .dental-text{
        padding: 0 25px;
        .txt{
          font-size: 28px;
        }
        .text-content{
          .small-txt {
            font-size: 35px;
          }
          .large-txt {
            font-size: 41px;
          }
        }
      }
    }
  }
}


@media(max-width:375px){
  .dental-page{
    .breadcrumb-content{
      .dental-text{
        padding: 0 25px;
        .txt{
          font-size: 22px;
        }
        .text-content{
          .small-txt {
            font-size: 30px;
          }
          .large-txt {
            font-size: 38px;
          }
        }
        .mtb-20{
          .main-btn{
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media(max-width:320px){
  .dental-page{
    .breadcrumb-content{
      .dental-text{
        .text-content{
          .small-txt {
            font-size: 25px;
          }
        }
      }
    }
  }
}