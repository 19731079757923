@import "../../styles/variable.scss";

.modal-dialog{
    .modal-content{
        border: 0px;
        border-radius: 8px;
        padding: 0 15px;
        .modal-header{
            .modal-title{
                color: $titleColor;
            }
            .close{
                outline: none;
            }
        }
        .modal-body{
            .input-form{
                padding-bottom: 10px;
                .text-field{
                    color: #000000cf;
                }
                .content{
                    margin-bottom: 5px;
                }
                input,textarea{
                    padding: 10px;
                    border: 1px;
                    background: $formField;
                    width: 100%;
                    outline: none;
                    border-radius: 5px;
                    font-size: 17px;
                    margin-bottom: 10px;
                }
                textarea{
                    height: 150px;
                    resize: none;
                }
                .error{
                    color: red;
                }
            }
            .main-btn{
                float: right;
            }
        }
        .modal-footer{
            .main-btn{
                background-color: transparent;
                border-color: $green;
                color: $green;
                padding: 8px 30px;
                &:hover{
                    background-color: $green;
                    border-color: $green;
                    color: white;
                }
            }
        }
    }
}