@import "../../styles/variable.scss";

.footer-section{
    .text-center{
        margin-bottom: 0;
        .footer-list{
            display: flex;
            align-items: center;
            justify-content: center;
            a{
                color: $contentColor;
            }
        }
        .flex-block{
            justify-content: space-between;
            .icon-bg{
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background: #E5E5E5;
                padding: 5px 0 0;
                i{
                    color: #666666;
                    font-size: 20px;
                }
            }
            .copy{
                font-size: 14px;
            }
        }
    }
}

@media(max-width:480px){
    .footer-section{
        .text-center{
            .footer-list{
                padding: 0;
                .nav-link {
                    padding: .5rem ;
                }
            }
            .flex-block {
                justify-content: space-between;
                flex-direction: column-reverse;
                ul{
                    flex-direction: row;
                    justify-content: center;
                    padding: 0;
                }
                .copy{
                    font-size: 12px;
                }
            }
        }
    }
}

@media(max-width:375px){
    .footer-section{
        .text-center{
            .footer-list{
                .nav-link {
                    font-size: 13px;
                }
            }
            .flex-block {
                .copy{
                    font-size: 10px;
                }
            }
        }
    }
}

@media(max-width:375px){
    .footer-section{
        .text-center{
            .footer-list{
                .nav-link {
                    font-size: 11px;
                    padding: 0 7px;
                }
            }
            .flex-block {
                .copy{
                    font-size: 8px;
                }
            }
        }
    }
}